// extracted by mini-css-extract-plugin
export var IndexParalax = "index-module--IndexParalax--03470";
export var Services = "index-module--Services--eca20";
export var backgroundVilao = "index-module--backgroundVilao--ca81d";
export var backgroundVilaoMobile = "index-module--backgroundVilaoMobile--3a091";
export var bannerText = "index-module--bannerText--efbfb";
export var bannerTextSustentabilidade = "index-module--bannerTextSustentabilidade--f7a3d";
export var blue = "index-module--blue--bbb6a";
export var blueBackground = "index-module--blueBackground--565c2";
export var boldLwart = "index-module--boldLwart--d65b3";
export var boldLwartMobile = "index-module--boldLwartMobile--58510";
export var boldSertoes = "index-module--boldSertoes--d9380";
export var boldSertoesMobile = "index-module--boldSertoesMobile--d2685";
export var borderLwart = "index-module--borderLwart--a95bb";
export var btnBannerSustentabilidade = "index-module--btnBannerSustentabilidade--03423";
export var closeGif = "index-module--closeGif--f197a";
export var customGradient = "index-module--customGradient--621b9";
export var customMobileFontHome = "index-module--customMobileFontHome--7e41a";
export var customSizeHome1 = "index-module--customSizeHome1--306fe";
export var fadeInCloseGif = "index-module--fadeInCloseGif--e6dbd";
export var fadeInDown = "index-module--fadeInDown--bfb6a";
export var fadeInText = "index-module--fadeInText--354d0";
export var fadeInTextMobile = "index-module--fadeInTextMobile--d0e1f";
export var fadeInTextTwo = "index-module--fadeInTextTwo--1e48d";
export var gifCar = "index-module--gifCar--26c2e";
export var green = "index-module--green--ffb5d";
export var homeInfo = "index-module--homeInfo--2d472";
export var homeInfoHover = "index-module--homeInfoHover--fb822";
export var homeInfoHoverES = "index-module--homeInfoHoverES--c8556";
export var iconClose = "index-module--iconClose--d6547";
export var infinityCicle = "index-module--infinityCicle--d1e6f";
export var infoItems = "index-module--infoItems--a4ff0";
export var lwart48Anos = "index-module--lwart48Anos--d9d4c";
export var lwartEmNumero = "index-module--lwartEmNumero--cef14";
export var lwartInfo = "index-module--lwartInfo--c4178";
export var lwartInfoDescription = "index-module--lwartInfoDescription--18762";
export var ourEssenceImage = "index-module--ourEssenceImage--12550";
export var ourEssenceMobileIcon = "index-module--ourEssenceMobileIcon--8a260";
export var ourEssenceText = "index-module--ourEssenceText--7f13c";
export var ourEssenceTextImage = "index-module--ourEssenceTextImage--d6407";
export var paralaxImageIndex = "index-module--paralaxImageIndex--4c628";
export var sectionWhite = "index-module--sectionWhite--e8a00";
export var sertoesLink = "index-module--sertoesLink--675d8";
export var sertoesLinkMobile = "index-module--sertoesLinkMobile--5663b";
export var swiperContainerVideo = "index-module--swiperContainerVideo--6c386";
export var text = "index-module--text--66842";
export var textBlue = "index-module--textBlue--293db";
export var textContainer = "index-module--textContainer--fa58c";
export var textContainerMobile = "index-module--textContainerMobile--770ca";
export var textGreen = "index-module--textGreen--25c47";
export var textMobile = "index-module--textMobile--69950";
export var textSertoesMobile = "index-module--textSertoesMobile--32d20";
export var textTwo = "index-module--textTwo--18de5";
export var topText = "index-module--topText--c6f1e";
export var topTextMobile = "index-module--topTextMobile--e8359";
export var vilaoOluc = "index-module--vilaoOluc--47b42";
export var vilaoOlucMobile = "index-module--vilaoOlucMobile--46a6e";
export var yearText = "index-module--yearText--7bb7c";
export var yearTextMobile = "index-module--yearTextMobile--07256";